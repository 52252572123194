import React, { useState } from "react";
import kora_chat1 from "../components/kora_chat1.svg";
import Rive from "@rive-app/react-canvas";
import OpenAI from "openai"; // Adjusted import

import check from "../components/check.svg";
import kora_thinking_cutoff from "../components/kora_thinking_cutoff.riv";

const MessageDisplay = ({ message }) => {
  if (!message) return null; // Don't display anything if there's no message
  return (
    <div className="messageDisplay">
      {message}
    </div>
  );
};

const Chatroom = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const fetchGPTResponse = async (prompt) => {
    try {
      const response = await fetch('/api/generate-response', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });
      const data = await response.json();
      return data.message;
    } catch (error) {
      console.error("Error fetching GPT-3 response:", error);
      return "Sorry, I couldn't fetch a response.";
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!message.trim()) return;
    setMessages([...messages, `You: ${message}`]);
    const gptResponse = await fetchGPTResponse(message);
    setMessages((msgs) => [...msgs, `Kora: ${gptResponse}`]);
    setMessage("");
  };

  

  return (
    <div className="interfaceContent">
            <p className="watermark">Kora</p>

      <h1 className="interfaceHeader">
        Watcha' Cravin'?
        
      </h1>

      <Rive className="chatKora" src={kora_thinking_cutoff} autoplay />

      <div className="chatEntries"> 
      {messages.map((msg, index) => (
        <MessageDisplay key={index} message={msg} />
      ))}
      </div>

      <form className="chatbox" onSubmit={handleFormSubmit}>
        <input
          className="chatentry"
          type="text"
          value={message}
          onChange={handleInputChange}
          placeholder="Ask for a rec!"
        />

        <button className="chatsubmit" type="submit">
          <img src={check} alt="Submit" />
        </button>
      </form>
 {/* Display each message */}
     
      <div className="interfaceBottom"></div>
    </div>
  );
};

export default Chatroom;
