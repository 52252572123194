import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Rive from "@rive-app/react-canvas";
import kora_thinking_cutoff from "../components/kora_thinking_cutoff.riv";
import new3 from "../components/new3.svg";
import aesthetic3 from "../components/aesthetic3.svg";
import budget3 from "../components/budget3.svg";

const Interface2 = ({ updateSelections }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { foodType } = location.state || {};

  const handleSelection = (priority) => {
    updateSelections({ priority });
    if (foodType === 'BBQ') {
      updateSelections({ dietary_restrictions: 'No Restriction' });
      navigate('/results1');
    } else {
      navigate('/interface3', { state: { foodType, priority } });
    }
  };

  return (
    <div className="interfaceContent">
      <div className="interfaceTop">
        <h1 className="onboardingHeader">Priority?</h1>
        <Rive className="interfaceKora" src={kora_thinking_cutoff} autoplay />
      </div>
      <div className="options">
        <img className="interfacebox" src={new3} onClick={() => handleSelection('New to the Scene')} />
        <img className="interfacebox" src={aesthetic3} onClick={() => handleSelection('Aesthetics')} />
        <img className="interfacebox" src={budget3} onClick={() => handleSelection('Budget Friendly')} />
      </div>
      <div className="interfaceBottom"></div>
    </div>
  );
};

export default Interface2;
