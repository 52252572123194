import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Rive from "@rive-app/react-canvas";
import kora_thinking_cutoff from "../components/kora_thinking_cutoff.riv";
import none3 from "../components/none3.svg";
import vegan3 from "../components/vegan3.svg";
import gluten3 from "../components/gluten3.svg";

const Interface3 = ({ updateSelections }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { foodType, priority } = location.state || {};

  const handleSelection = (dietaryRestrictions) => {
    updateSelections({ dietary_restrictions: dietaryRestrictions });
    navigate('/results1');
  };

  return (
    <div className="interfaceContent">
      <div className="interfaceTop">
        <h1 className="onboardingHeader">Restrictions?</h1>
        <Rive className="interfaceKora" src={kora_thinking_cutoff} autoplay />
      </div>
      <div className="options">
        <img className="interfacebox" src={none3} onClick={() => handleSelection('No Restriction')} />
        <img className="interfacebox" src={vegan3} onClick={() => handleSelection('Vegan')} />
        <img className="interfacebox" src={gluten3} onClick={() => handleSelection('Gluten Free')} />
      </div>
      <div className="interfaceBottom"></div>
    </div>
  );
};

export default Interface3;
