import React, { useState } from "react";
import { Link } from "react-router-dom";
import continue_button from "../components/continue_button.svg";
import kora_board from "../components/kora_board.svg";
import Rive from "@rive-app/react-canvas";
import kora_thinking_cutoff from "../components/kora_thinking_cutoff.riv";
import clipboard from "../components/clipboard.svg";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAi4In8VQ5EqY6hJivrzJ33YRiDGQ_zHfs",
  authDomain: "koraeats-1bc49.firebaseapp.com",
  projectId: "koraeats-1bc49",
  storageBucket: "koraeats-1bc49.appspot.com",
  messagingSenderId: "974709139382",
  appId: "1:974709139382:web:814d17de2b5df55b224317",
  measurementId: "G-GV56XZKGF7"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function Registration1() {
  const [emailOrPhone, setEmailOrPhone] = useState("");

  const handleInputChange = (e) => {
    setEmailOrPhone(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  const handleGoogleSignIn = () => {
    console.log("Google sign-in button clicked");
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("Google sign-in result:", result);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        console.log('User signed in:', user);
      })
      .catch((error) => {
        console.error('Error during Google sign-in:', error);
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error('Error details:', { errorCode, errorMessage, email, credential });
      });
  };

  return (
    <div className="registrationContent">
      <div className="registrationTop">
        <h1>Get Started!</h1>
      </div>
      <Rive className="registrationKora" src={kora_thinking_cutoff} autoplay />
      <div className="overlayContainer">
        <img className="clipboard" src={clipboard} alt="clipboard" />
        <form className="registrationBottom" onSubmit={handleSubmit}>
          {/* Add more inputs as needed */}
        </form>

        <Link to="/Registration2">
          <button className="overlayInput1" onClick={handleGoogleSignIn}>Sign in with Google</button>
        </Link>
      </div>
    </div>
  );
}

export default Registration1;