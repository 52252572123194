import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import share1 from "../components/share1.svg";
import proProfile from "../components/proProfile.svg";
import basicProfile from "../components/basicProfile.svg";
import tiktok1 from "../components/tiktok1.svg";
import instagram1 from "../components/instagram1.svg";
import twitter1 from "../components/twitter1.svg";
import frame from "../components/kora_qr.png";
import { getAuth } from "firebase/auth";

const Profile = () => {
  const [name, setName] = useState(""); // State to store the user's name
  const auth = getAuth();

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const idToken = await user.getIdToken();
          const response = await fetch('/api/get-user', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({ uid: user.uid })
          });

          const result = await response.json();
          if (response.ok && result.user) {
            setName(result.user.display_name); // Set the name from the backend
          } else {
            console.error('Failed to fetch user data:', result.error);
          }
        }
      } catch (err) {
        console.error('Error fetching user name:', err);
      }
    };

    fetchUserName(); // Fetch the name from the backend
  }, [auth]);

  const handleShare = async () => {
    // Existing share functionality...
  };

  return (
    <div className="profileContent">
      <h1 className="onboardingHeader">
        {" "}
        Welcome Back!
        <h1 className="profileHeader">{name}</h1> {/* Display the name here */}
      </h1>

      <div>
        <Link to="/Interface1">
          <img className="profileBody" src={basicProfile} alt="Basic Profile" />
        </Link>
        <Link to="/Pro">
          <img className="profileBody" src={proProfile} alt="Pro Profile" />
        </Link>
      </div>

      <div className="profileBottom">
        <img src={frame} className="qrCode" alt="frame" />
        {/*           <a className="socialFooter" href="https://www.instagram.com/korathefoodie/" target="_blank" rel="noopener noreferrer">
            <img  src={instagram1} alt="Instagram"/>
          </a><a className="socialFooter" href="https://www.tiktok.com/@korathefoodie?_t=8oBmAQTSL6c&_r=1" target="_blank" rel="noopener noreferrer">
            <img src={tiktok1} alt="Tiktok"/>
          </a> */}
      </div>
      <div className="proContainer">{/* Additional content here */}</div>
    </div>
  );
};

export default Profile;
