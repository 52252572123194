import React from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import kora_pro from "../components/kora_pro.svg";
import Rive from "@rive-app/react-canvas";
import leaves_blowing from "../components/leaves_blowing.riv";

import { Link } from "react-router-dom";


// Your Stripe public key
const stripePromise = loadStripe("your_stripe_public_key_here");

const SubscriptionForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);

    // Use your stripe object to create a payment method and subscribe the user
    // You'll need to implement this part based on your backend API
  };

  return (
    
    <form onSubmit={handleSubmit}>
      
      
      <div className="subscribeContent">
      <Rive className="leaves_blowing" src={leaves_blowing} autoplay />

        <h1 className="subscribeHeader">
          <img className="subscribeKora" src = {kora_pro}/>
          Coming Soon
        </h1>
        
        <div className="confirming">
 

{/* <CardElement />
      <button className = "subscribeButton" type="submit" disabled={!stripe}>
        Subscribe
      </button>*/}

      <div>
      <Link className = "goBack" to="/interface1">
       Go Back
      </Link>
      </div>       
       
      </div>
      </div>
      
    </form>
    
    
  );
};

const SubscriptionPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionForm />
    </Elements>
  );
};

export default SubscriptionPage;
