import clipboard2 from "../components/clipboard2.svg";
import React from "react";
import { Link } from "react-router-dom";
import continue_button from "../components/continue_button.svg";

const Pro = () => {
  return (
    <div className="proContent">
      <h1 className="proHeader">
        Kora<h1 className="tiredbro">Pro</h1>
      </h1>
      <h3 className="proText">
        Pro members find favorite spots twice as often.
      </h3>

      <div className="proContainer">
        <img className="clipboard2" src={clipboard2} alt="clipboard" />

        {/* Link button to go back to the profile page */}
        <Link to="/profile">
          <button className="proButton">My Profile</button>
        </Link>
      </div>
    </div>
  );
};

export default Pro;
