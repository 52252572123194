import React from "react";
import { Link } from "react-router-dom";
import Rive from "@rive-app/react-canvas";
import koraWave3 from "../components/kora_wave3.riv";
import leaves_blowing from "../components/leaves_blowing.riv";

const Landing = () => {
  return (
    <div className="landingContent">
      <Rive className="leaves_blowing" src={leaves_blowing} autoplay />

      <div >
        <h1 className="redirectHeader">Kora</h1>
        <h3 className="landing2">A foodie's best friend.</h3>
      </div>


      <Rive className="kora_wave3" src={koraWave3} autoplay />

      <Link className="actionButton" to="/registration1">
        Get Started
      </Link>
    </div>
  );
};

export default Landing;
