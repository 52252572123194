import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import Landing from "./pages/Landing";
import Registration1 from "./pages/Registration1";
import Registration2 from "./pages/Registration2";
import Onboarding1 from "./pages/Onboarding1";
import Onboarding2 from "./pages/Onboarding2";
import Onboarding3 from "./pages/Onboarding3";
import Interface1 from "./pages/Interface1";
import Interface2 from "./pages/Interface2";
import Interface3 from "./pages/Interface3";
import Results1 from "./pages/Results1";
import Results2 from "./pages/Results2";
import Rivetest from "./pages/Rivetest";
import Interceptor from "./pages/Interceptor";
import Chatroom from "./pages/Chatroom";
import Standalone from "./pages/Standalone";
import SubscriptionPage from "./pages/SubscriptionPage";
import Profile from "./pages/Profile";
import profileButton from "./components/profileButton.svg";
import Pro from "./pages/Pro";
import "./App.css"; // Adjust the path as necessary if your file structure is different

function App() {
  const [isStandaloneMode, setIsStandaloneMode] = useState(false); // Example state to track standalone mode


  
  const [selections, setSelections] = useState({
    food_type: "",
    priority: "",
    dietary_restrictions: "",
  });

  
// isLoggedIn function to check login status
const isLoggedIn = () => {
  return localStorage.getItem("authToken") ? true : false;
};

  const NavigateBasedOnMode = () => {
    const navigate = useNavigate();
    useEffect(() => {
      if (isStandaloneMode) {
        navigate('/standalone');
      } else {
        navigate('/landing');
      }
    }, [isStandaloneMode, navigate]); // Depend on isStandaloneMode to re-run when it changes

    return null; // This component does not render anything
  };
  
  const updateSelections = (newSelections) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      ...newSelections,
    }));
  };

  const HeaderWithCondition = () => {
    const location = useLocation(); // Now useLocation is used within the context of Router
    if (location.pathname === '/onboarding1' || location.pathname === '/onboarding2' || location.pathname === '/onboarding3'  || 
      location.pathname === '/standalone' || location.pathname === '/landing' || location.pathname === '/registration1' || 
      location.pathname === '/registration2'   ) {
      return null; // Do not render the header on these pages
    }
    return (
      <header className="App-header">
        <h3 className="occupy1">Beta</h3>
        <h3 className="occupy">Kora</h3>
        <Link className="occupy" to="/profile"> {/* Ensure the path is correctly set to /profile */}
          <img src={profileButton} alt="logo" />
        </Link>
      </header>
    );
  };


  return (
    <Router>
      <div className="App">
      <HeaderWithCondition /> {/* Use the custom component */}
     
        <main>
          <Routes>
          <Route path="/" element={isLoggedIn() ? <Profile /> : <Landing />} />

            <Route path="/standalone" element={<Standalone />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/registration1" element={<Registration1 />} />
            <Route path="/registration2" element={<Registration2 />} />

            <Route path="/onboarding1" element={<Onboarding1 />} />
            <Route path="/onboarding2" element={<Onboarding2 />} />
            <Route path="/onboarding3" element={<Onboarding3 />} />
            <Route
              path="/interface1"
              element={<Interface1 updateSelections={updateSelections} />}
            />
            <Route
              path="/interface2"
              element={<Interface2 updateSelections={updateSelections} />}
            />
            <Route
              path="/interface3"
              element={<Interface3 updateSelections={updateSelections} />}
            />
            <Route
              path="/results1"
              element={<Results1 selections={selections} />}
            />
            <Route path="/results2" element={<Results2 />} />
            <Route path="/rivetest" element={<Rivetest />} />
            <Route path="/interceptor" element={<Interceptor />} />
            <Route path="/chatroom" element={<Chatroom />} />
            <Route path="/SubscriptionPage" element={<SubscriptionPage />} />
            <Route path="/pro" element={<Pro />} />
            <Route path="/landing" element={<Landing />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
