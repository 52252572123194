import React from "react";
import { useNavigate } from "react-router-dom";
import Rive from "@rive-app/react-canvas";
import kora_thinking_cutoff from "../components/kora_thinking_cutoff.riv";
import axios from "axios";
import bbq3 from "../components/bbq3.svg";
import asian3 from "../components/asian3.svg";
import healthy3 from "../components/healthy3.svg";
import latin3 from "../components/latin3.svg";
import cafe1 from "../components/cafe1.svg";
import { Link } from "react-router-dom";

const Interface1 = ({ updateSelections }) => {
  const navigate = useNavigate();

  const handleSelection = async (foodType) => {
    updateSelections({ food_type: foodType });

    try {
      if (foodType === "Cafe") {
        navigate("/pro");
      } else {
        const response = await axios.post("/api/example/interface1", {
          food_type: foodType,
        });
        console.log("Server response:", response.data);
        navigate("/interface2", { state: { foodType } });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <div className="interfaceContent">
      <div className="interfaceTop">
        <h1 className="onboardingHeader">Whatcha craving?</h1>
        <Rive className="interfaceKora" src={kora_thinking_cutoff} autoplay />
      </div>
     

      <div className="interfaceBottom"></div>
      <div className="options">
        
        {/*
        <img
          className="interfacebox"
          src={cafe1}
          alt="cafe"
          onClick={() => handleSelection("Cafe")}
        /> 
        */}

        <img
          className="interfacebox"
          src={bbq3}
          alt="bbq1"
          onClick={() => handleSelection("BBQ")}
        />
        <img
          className="interfacebox"
          src={asian3}
          alt="asian1"
          onClick={() => handleSelection("Asian")}
        />
        <img
          className="interfacebox"
          src={healthy3}
          alt="healthy1"
          onClick={() => handleSelection("Mediterranean")}
        />
        <img
          className="interfacebox"
          src={latin3}
          alt="latin1"
          onClick={() => handleSelection("Mexican")}
        />
      </div>
    </div>
  );
};

export default Interface1;
