import React from "react";
import { Link } from "react-router-dom";
import Rive from '@rive-app/react-canvas';



const Rivetest = () => {
  return (
    <div>
        <Rive
    src="https://cdn.rive.app/animations/vehicles.riv"
    stateMachines="bumpy"
  />
        <h1>rivetest</h1>
    </div>
  );
};


export default Rivetest;