import React from "react";
import { Link } from "react-router-dom";
import basicplan from "../components/basicplan.svg";
import austin_redirect from "../components/austin_redirect.svg";
import mexicocity_redirect from "../components/mexicocity_redirect.svg";
import nyc_redirect from "../components/nyc_redirect.svg";

const Interceptor = () => {
  return (
    <div className="interceptorContent">
      <h1 className="interceptorHeader">
        The best food in<h1 className="tiredbro">Austin.</h1>
      </h1>

      <Link to="/Interface1">
        <img  src={austin_redirect} alt="logo" />
        <img  src={mexicocity_redirect} alt="logo" />
      </Link>

    
      


    </div>
  );
};

export default Interceptor;
