import React from "react";
import { Link, useLocation } from "react-router-dom";
import kora_clap from "../components/kora_clap.svg";
import "../App.css"; // Ensure this import is correctly pointing to your CSS file
import trysomethingnew from "../components/trysomethingnew.svg";
import viewmap from "../components/viewmap.svg";
import viewmenu from "../components/viewmenu.svg";

const Results2 = () => {
  const location = useLocation();
  const { selectedRecommendation, selectedURL, selectedMenuURL } =
    location.state;

  console.log(
    "Received state in Results2:",
    selectedRecommendation,
    selectedURL,
    selectedMenuURL
  );

  if (!selectedRecommendation) {
    return (
      <div className="resultsContent">
        <div className="interfaceTop">
          <h1 className="interfaceHeader">No selection made.</h1>
        </div>
        <img src={kora_clap} className="results2Kora" alt="logo" />

        <div className="interfaceBottom"></div>
      </div>
    );
  }

  return (
    <div className="resultsContent">
      <img className="results2Kora" src={kora_clap} alt="logo" />

      <div className="resultsTop">
        <h1 className="interceptorHeader">
          Enjoy{" "}
          <span className="interceptorHeader2">{selectedRecommendation}!</span>
        </h1>
        <div className="icon-links">
          <a
            href={selectedURL}
            target="_blank"
            rel="noopener noreferrer"
            className="custom-link"
          >
            <img src={viewmap} alt="View Map" />
          </a>
          <a
            href={selectedMenuURL}
            target="_blank"
            rel="noopener noreferrer"
            className="custom-link"
          >
            <img src={viewmenu} alt="View Menu" />
          </a>
        </div>
        <Link to="/interface1">
          <img
            className="resultsButton"
            src={trysomethingnew}
            alt="Try Something New"
          />
        </Link>
      </div>
    </div>
  );
};

export default Results2;
