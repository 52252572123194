import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import kora_proud from "../components/kora_proud.svg";

const Results1 = ({ selections }) => {
  const [recommendations, setRecommendations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        // Log the selections being sent to the server
        console.log("Fetching recommendations with selections:", selections);

        // Send the selections to the server and receive recommendations
        const response = await axios.post('/api/example/results', selections);

        // Log the full response received from the server
        console.log("Received full response:", response.data);

        // Log just the recommendations array
        console.log("Received recommendations:", response.data.recommendations);

        // Set the recommendations state with the data received from the server
        setRecommendations(response.data.recommendations);

        // Log the recommendations state after setting it
        console.log("Recommendations state after fetch:", response.data.recommendations);

      } catch (error) {
        console.error('Error fetching recommendations', error);
      }
    };

    fetchRecommendations();
  }, [selections]);

  const handleOptionClick = (rec) => {
    console.log(`Navigating to Results2 with state: ${rec.name}, URL: ${rec.url}, and Menu URL: ${rec.menu_url}`);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(rec.name)}`;
    navigate("/results2", { state: { selectedRecommendation: rec.name, selectedURL: googleMapsUrl, selectedMenuURL: rec.menu_url } });
  };

  return (
    <div className="resultsContent">
      <div className="resultsTop">
        <h1 className="onboardingHeader">Choose!</h1>
      </div>
      <div className="options2">
        {recommendations.length > 0 ? (
          recommendations.map((rec, index) => (
            <div className="optionbox" key={index} onClick={() => handleOptionClick(rec)}>
              <span className="optiontext">{rec.name}</span>
            </div>
          ))
        ) : (
          <p>No recommendations found</p>
        )}
      </div>
      <img className="kora_wave3" src={kora_proud} alt="Kora Proud" />
    </div>
  );
};

export default Results1;
