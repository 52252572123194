import React from 'react';
import { Link } from 'react-router-dom';
import continue2 from '../components/continue2.svg';
import Rive from "@rive-app/react-canvas";
import kora_driving from "../components/kora_driving.riv"



const Onboarding2 = () => {
  return (
    <div className="onboardingContent">


        <h1 className="onboardingHeader">
          Let Kora <br></br>decide.
        </h1>
        <Link to="/Onboarding3">
          <img
            className="onboardingbutton"
            src={continue2}
            alt="continue"
          />
        </Link>

        <Rive className="kora_driving" src={kora_driving} autoplay />

      <div className="onboardingBottom">

        
      </div>
    </div>

  );
};

export default Onboarding2;