import React from "react";
import { Link } from "react-router-dom";
import continue1 from "../components/continue1.svg";
import Rive from "@rive-app/react-canvas";
import kora_driving from "../components/kora_driving.riv"


const Onboarding1 = () => {
  return (
    <div className="onboardingContent">
            
      {/*<p className="watermark">Kora</p> */}
      <h1 className="onboardingHeader">
          What do you<br></br> want to eat?
          
        </h1>
        <Link to="/Onboarding2">
          <img
            className="onboardingbutton"
            src={continue1}
            alt="continue"
          />
        </Link>
      <Rive className="kora_driving" src={kora_driving} autoplay />

            
      


      <div className="onboardingBottom">

        
      </div>

    </div>
  );
};

export default Onboarding1;
