import React from "react";
import { Link } from "react-router-dom";
import continue_button from "../components/continue_button.svg";
import Rive from "@rive-app/react-canvas";
import kora_driving from "../components/kora_driving.riv"



const Onboarding3 = () => {
  return (
    <div className="onboardingContent">


        <h1 className="onboardingHeader">
          For daily <br></br> updated recs.
        </h1>
        <Link to="/Registration1">
          <img
            className="onboardingbutton"
            src={continue_button}
            alt="continue"
          />
        </Link>

        <Rive className="kora_driving" src={kora_driving} autoplay />

      <div className="onboardingBottom">

        
      </div>
    </div>
  );
};

export default Onboarding3;
