import React from "react";
import { Link } from "react-router-dom";
import Rive from "@rive-app/react-canvas";
import kora_proud from "../components/kora_proud.svg";
import leaves_blowing from "../components/leaves_blowing.riv";
import install1 from "../components/install1.svg";

const Standalone = () => {
  return (
    <div className="landingContent">
      <Rive className="leaves_blowing" src={leaves_blowing} autoplay />
      <div className="jesuschrist">
        <h1 className="redirectHeader">Add to home for the best experience.</h1>
        
      </div>
      <div >
          <img className="installimage1"src={install1} />
        </div>

      <img src={kora_proud} alt="logo" />

      <Link className="redirectButton" to="/landing">
        Continue Anyway
      </Link>
    </div>
  );
};

export default Standalone;
