import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Rive from "@rive-app/react-canvas";
import kora_thinking_cutoff from "../components/kora_thinking_cutoff.riv";
import clipboard from "../components/clipboard.svg";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import debounce from 'lodash.debounce';

const Registration2 = () => {
  const [name, setName] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);  // New state to track if the user is new
  const navigate = useNavigate();
  const auth = getAuth();

  const checkUserExists = async (uid) => {
    try {
      const response = await fetch('/api/get-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.user) {
          console.log("User exists in the database:", data.user);
          setIsNewUser(false);
          navigate('/interceptor');  // Redirect to the next page if the user exists
        } else {
          setIsNewUser(true);  // User doesn't exist, proceed to the registration page
        }
      } else {
        console.error('Failed to check user existence:', response.statusText);
      }
    } catch (err) {
      console.error('Error checking user existence:', err);
      setIsNewUser(true); // If there's an error, treat the user as new for safety
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Authenticated user:", user);
        checkUserExists(user.uid); // Check if the user exists in the database
      }
    });
  }, [auth]);

  const debouncedSubmit = debounce(async (user) => {
    const email = user.email;
    const uid = user.uid;

    console.log("Firebase User:", { uid, email, displayName: name });

    try {
      const response = await fetch('/api/create-or-link-user', { // Updated route
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid, email, displayName: name }),
      });

      if (response.ok) {
        console.log("User successfully created or already exists.");
        navigate('/interceptor');
      } else {
        console.error('Failed to create user:', response.statusText);
      }
    } catch (err) {
      console.error('Error creating user:', err);
    }
  }, 1000);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setIsTyping(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsTyping(false);
    debouncedSubmit(auth.currentUser);
  };

  return (
    <div className="registrationContent">
      <div className="registrationTop">
        <h1>What's Your Name?</h1>
      </div>
      <Rive className="registrationKora" src={kora_thinking_cutoff} autoplay />
      <div className="overlayContainer">
        <img className="clipboard" src={clipboard} alt="clipboard" />
        {isNewUser && (
          <form onSubmit={handleSubmit}>
            <label className="overlayInput3">
              <input
                type="text"
                value={name}
                onChange={handleNameChange}
                placeholder="Enter your display name"
                required
              />
            </label>
            <button className="overlayInput2" type="submit">
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Registration2;
